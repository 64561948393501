import React, { ComponentProps, useEffect, useState } from "react";
import { Box } from "../core/v2/Box/Box";
import LpBackground from "../../images/lp-background.jpeg";
import { useLandingPageMedia } from "./LandingPageView";

const useScrollYPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

type Props = Pick<ComponentProps<typeof Box>, "sx">;
export const DynamicBackground: React.FC<Props> = ({ sx = {} }) => {
  const scrollY = useScrollYPosition();
  const { minMediaMD } = useLandingPageMedia();
  const scalingFactor = minMediaMD ? 1.3 : 1.5;

  return (
    <Box
      width="100vw"
      height="100vh"
      position="fixed"
      top={0}
      left={0}
      zIndex={-1}
      sx={{
        background: `url(${LpBackground})`,
        backgroundSize: "cover",
        transform: `translateY(-${scrollY * 0.05}px) scale3d(${scalingFactor}, ${scalingFactor}, ${scalingFactor})`,
        ...sx,
      }}
    />
  );
};
