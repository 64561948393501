import React from "react";
import { Box } from "../core/v2/Box/Box";
import { Logo } from "../core/Logo/Logo";
import { useIntersectionObserver } from "usehooks-ts";
import { Stack } from "@mui/material";
import { AuthButtons } from "./AuthButtons";
import { useLandingPageMedia } from "./LandingPageView";
import { useHeritageV2Palette } from "../../utils/hooks/useHeritageV2Palette";
import { config } from "../../utils/config";

export const useTopbarConfig = () => {
  const { minMediaMD, minMediaXS } = useLandingPageMedia();
  const containerSpace = minMediaXS ? "3rem" : "2rem";
  const logoHeight = minMediaMD ? "2.5rem" : "1.875rem";

  return { containerSpace, logoHeight };
};

type Props = {
  disableScrollAnimation?: boolean;
};

export const TopNavbar: React.FC<Props> = ({ disableScrollAnimation }) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    initialIsIntersecting: true,
  });
  const inView = !!isIntersecting;
  const { minMediaMD } = useLandingPageMedia();
  const palette = useHeritageV2Palette();
  const { containerSpace, logoHeight } = useTopbarConfig();

  return (
    <>
      <Box
        ref={ref}
        p={containerSpace}
        position="absolute"
        top="0rem"
        left="0rem"
        width="100%"
        boxSizing="border-box"
        sx={{
          visibility: inView ? 1 : 0,
        }}
      >
        <Box mx="auto">
          <a href={config.landingPageUrl}>
            <Logo height={logoHeight} fill={palette.invariantWhite} />
          </a>
        </Box>
      </Box>

      {!disableScrollAnimation ? (
        <Box
          px={containerSpace}
          position="fixed"
          top="0px"
          left="0px"
          width="100%"
          maxHeight={inView ? "0rem" : "5rem"}
          overflow="hidden"
          height="100%"
          boxSizing="border-box"
          sx={{
            backdropFilter: "blur(15px)",
            transition: "max-height 0.3s ease-in-out",
          }}
          borderBottom={`1px solid rgba(255, 255, 255, 0.08)`}
          display={minMediaMD ? "flex" : "none"}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          zIndex={999}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Logo height="1.875rem" fill={palette.invariantWhite} />

            <Box>
              <AuthButtons />
            </Box>
          </Stack>
        </Box>
      ) : undefined}
    </>
  );
};
