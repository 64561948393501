import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Box } from "../core/v2/Box/Box";
import { useHeritageV2Palette } from "../../utils/hooks/useHeritageV2Palette";
import { DetailsMinus, DetailsPlus } from "../core/v2/Icon/Icon";
import { useLandingPageMedia } from "./LandingPageView";

type AccordionItem = {
  title: string;
  body: string;
};

type Props = {
  items: Array<AccordionItem>;
};

export const Accordion: React.FC<Props> = ({ items }) => {
  const [openItem, setOpenItem] = useState(0);
  const palette = useHeritageV2Palette();
  const { minMediaXS } = useLandingPageMedia();

  return (
    <Stack flexDirection="column" gap={3}>
      {items.map(({ title, body }, index) => {
        const isOpen = index === openItem;
        const Icon = isOpen ? DetailsMinus : DetailsPlus;

        return (
          <Box key={title}>
            <button
              style={{
                cursor: "pointer",
                background: "transparent",
                border: "0px",
                width: "100%",
                padding: "0px",
              }}
              onClick={() => setOpenItem(index)}
            >
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Typography
                  variant="h1"
                  fontSize={minMediaXS ? "2rem" : "1.5rem"}
                  color={palette.invariantWhite}
                  border="0px"
                  bgcolor="transparent"
                  textAlign="left"
                  lineHeight={1.4}
                >
                  {title}
                </Typography>

                <Typography color={palette.invariantWhite}>
                  <Icon variant="medium" />
                </Typography>
              </Stack>
            </button>

            <Box
              mt={1}
              sx={{
                display: "grid",
                gridTemplateRows: isOpen ? "1fr" : "0fr",
                transition: "grid-template-rows 0.2s ease-in-out",
              }}
            >
              <Typography
                fontSize="1.125rem"
                color={palette.accentBg200}
                overflow="hidden"
              >
                {body}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Stack>
  );
};
