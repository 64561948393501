import React, { PropsWithChildren, useEffect } from "react";
import { Stack, StackProps } from "@mui/material";
import { useIntersectionObserver } from "usehooks-ts";
import { useLandingPageMedia } from "./LandingPageView";

export enum SectionKind {
  About = "About",
  Mission = "Mission",
  Story = "Story",
  Contact = "Contact",
}

type Props = PropsWithChildren<{ onInView: () => void } & StackProps>;

export const Section: React.FC<Props> = ({ children, onInView, ...rest }) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });
  const inView = !!isIntersecting;
  const { minMediaMD } = useLandingPageMedia();

  useEffect(() => {
    if (inView) onInView();
  }, [inView, onInView]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      px={minMediaMD ? undefined : 3}
      {...rest}
      ref={ref}
    >
      {children}
    </Stack>
  );
};
