import { Stack } from "@mui/material";
import React from "react";
import { Box } from "../core/v2/Box/Box";
import { Button } from "../core/v2/Button/Button";
import { useHeritageV2Palette } from "../../utils/hooks/useHeritageV2Palette";
import { Lock } from "../core/v2/Icon/Icon";
import { config } from "../../utils/config";

type Props = {
  hideSignup?: boolean;
};

export const AuthButtons: React.FC<Props> = ({ hideSignup }) => {
  const palette = useHeritageV2Palette();

  return (
    <Stack
      flexDirection="row"
      flexWrap="wrap"
      gap={1}
      sx={{
        "& .MuiButton-outlined:not(:hover)": {
          borderColor: palette.invariantWhite,
          color: palette.invariantWhite,
        },

        "& .MuiButton-contained:not(:hover)": {
          background: palette.invariantWhite,
          borderColor: palette.invariantWhite,
          color: palette.text900,
        },
      }}
    >
      {!hideSignup ? (
        <Box>
          <Button variant="primary" href={config.sinupPageUrl}>
            Request access
          </Button>
        </Box>
      ) : undefined}

      <Box>
        <Button variant="secondary" LeftIcon={Lock} href={config.loginPageUrl}>
          Membership login
        </Button>
      </Box>
    </Stack>
  );
};
