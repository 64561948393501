import React from "react";
import { Box } from "../core/v2/Box/Box";
import { OutlineIconLogo } from "../core/Logo/Logo";
import {
  GlobalStyles,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useHeritageV2Palette } from "../../utils/hooks/useHeritageV2Palette";
import { Accordion } from "./Accordion";
import { Section, SectionKind } from "./Section";
import { TopNavbar } from "./TopNavbar";
import { P, match } from "ts-pattern";
import { DynamicBackground } from "./DynamicBackground";
import { AuthButtons } from "./AuthButtons";
import { DetailsMinus } from "../core/v2/Icon/Icon";

const infoEmail = "info@heritageholdings.co";
const linkedinUrl = "https://www.linkedin.com/company/heritage-holdings/";

function scrollToSection(section: SectionKind) {
  const el = document.getElementById(section);

  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
}

export function useLandingPageMedia() {
  const minMediaXXL = useMediaQuery("(min-width:1401px)");
  const minMediaXL = useMediaQuery("(min-width:1101px)");
  const minMediaLG = useMediaQuery("(min-width:951px)");
  const minMediaMD = useMediaQuery("(min-width:801px)");
  const minMediaXS = useMediaQuery("(min-width:389px)");

  return { minMediaXXL, minMediaXL, minMediaLG, minMediaMD, minMediaXS };
}

export const LandingPageView = () => {
  const palette = useHeritageV2Palette();
  const [activeSection, setActiveSection] = React.useState<SectionKind>(
    SectionKind.About,
  );

  const { minMediaXXL, minMediaXL, minMediaLG, minMediaMD, minMediaXS } =
    useLandingPageMedia();

  const computedScale = match([minMediaXXL, minMediaXL, minMediaMD, minMediaXS])
    .with([true, P._, P._, P._], () => "110%")
    .with([P._, true, P._, P._], () => "100%")
    .with([P._, P._, true, P._], () => "90%")
    .with([P._, P._, P._, true], () => "90%")
    .otherwise(() => "80%");

  const innerContainerSize = "50rem !important";

  return (
    <>
      <GlobalStyles styles={{ html: { fontSize: computedScale } }} />
      <DynamicBackground />

      <Box
        sx={{
          minHeight: "100vh",
          fontSize: "100%",
        }}
      >
        <TopNavbar />

        <Box
          position="fixed"
          right="0px"
          top="50%"
          zIndex={999}
          display={minMediaLG ? "block" : "none"}
          sx={{ transform: "translateY(-50%)" }}
        >
          <Stack flexDirection="column" gap={1} pr={1}>
            {Object.entries(SectionKind).map(([key, value]) => (
              <button
                key={key}
                onClick={() => scrollToSection(value)}
                style={{
                  borderWidth: "0rem",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  gap={1}
                  sx={{
                    opacity: key === activeSection ? 1 : 0.5,
                  }}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={"1.125rem"}
                    color={palette.invariantWhite}
                    textAlign="right"
                  >
                    {value}
                  </Typography>

                  {minMediaXXL ? (
                    <Typography
                      color={palette.invariantWhite}
                      sx={{
                        opacity: key === activeSection ? 1 : 0,
                      }}
                    >
                      <DetailsMinus variant="medium" />
                    </Typography>
                  ) : undefined}
                </Stack>
              </button>
            ))}
          </Stack>
        </Box>

        <Section
          onInView={() => setActiveSection(SectionKind.About)}
          id={SectionKind.About}
        >
          <Stack flexDirection="row" flexWrap={minMediaMD ? undefined : "wrap"}>
            <Typography
              variant="h1"
              fontSize={minMediaXS ? "4.5rem" : "3rem"}
              color={palette.invariantWhite}
              maxWidth={minMediaMD ? "21rem" : undefined}
              width={minMediaMD ? undefined : "100%"}
            >
              Growing more than capital
            </Typography>

            <Box
              maxWidth={minMediaMD ? "25rem !important" : undefined}
              marginLeft={minMediaMD ? "-6rem" : undefined}
              alignSelf={minMediaMD ? "flex-end" : undefined}
            >
              <Typography
                fontWeight={400}
                fontSize={minMediaMD ? "1.7rem" : "1.4rem"}
                color={palette.accentBg200}
                pt={minMediaMD ? "11.5rem" : 3}
              >
                Heritage is a private investment platform built by families for
                families.
              </Typography>

              <Box mt={2}>
                <AuthButtons />
              </Box>
            </Box>
          </Stack>
        </Section>

        <Section
          onInView={() => setActiveSection(SectionKind.Mission)}
          id={SectionKind.Mission}
        >
          <Box>
            <Typography
              variant="h1"
              fontSize={minMediaXS ? "3.875rem" : "2.8rem"}
              color={palette.invariantWhite}
            >
              Mission
            </Typography>

            <Box
              maxWidth={innerContainerSize}
              mt={2}
              pl={minMediaMD ? 4 : undefined}
            >
              <Typography
                fontWeight={400}
                fontSize="1.375rem"
                color={palette.accentBg200}
              >
                Collectively grow the legacy of the most respected families and
                entrepreneurs through access to the world’s top performing
                alternative asset funds and opportunities.
              </Typography>

              <Box mt={4}>
                <Accordion
                  items={[
                    {
                      title: "We believe in mastery",
                      body: "We learned through generations of business building that mastery requires real field experience. We are unique in having a team of investment professionals with firsthand experience from leading Alternative Funds and a deep understanding of the industry from within. We cultivated relationships with a select group of the world's most reputable fund managers, developed a proprietary methodology for assessing funds and built one of the most extensive data sets in the industry. We aim to leave no stone unturned.",
                    },

                    {
                      title: "We believe in alignment",
                      body: "We’re a select group of globally respected families and entrepreneurs, all with skin in the game and aligned in accessing the world's most promising alternative assets. Many of us still operate multi-billion dollar businesses and look to grow more than capital together.",
                    },

                    {
                      title: "We believe in technology",
                      body: "We developed a state-of-the-art platform that provides us with the infrastructure to streamline, centralize and monitor investments. Our foundational roots allow us to craft bespoke investment portfolios that contribute to the preservation and growth of our collective legacy.",
                    },
                  ]}
                />
              </Box>
            </Box>
          </Box>
        </Section>

        <Section
          onInView={() => setActiveSection(SectionKind.Story)}
          id={SectionKind.Story}
        >
          <Box>
            <Typography
              variant="h1"
              fontSize={minMediaXS ? "3.875rem" : "2.8rem"}
              color={palette.invariantWhite}
            >
              Story
            </Typography>

            <Box
              maxWidth={innerContainerSize}
              mt={2}
              pl={minMediaMD ? 4 : undefined}
            >
              <Typography
                fontWeight={400}
                fontSize="1.375rem"
                color={palette.accentBg200}
              >
                Established in 2018 by a global collective of prominent families
                and entrepreneurs, Heritage is a private investment platform
                that offers access to top-tier opportunities. We steward
                significant capital across private markets, curating portfolios
                that reflect our deep industry insights.
              </Typography>
            </Box>
          </Box>
        </Section>

        <Section
          onInView={() => setActiveSection(SectionKind.Contact)}
          flexDirection="column"
          gap={minMediaMD ? "8.125rem" : "3.125rem"}
          id={SectionKind.Contact}
        >
          <Box maxWidth={innerContainerSize} width="100%">
            <Stack
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
              gap={4}
              flexWrap={minMediaMD ? undefined : "wrap"}
              borderTop={
                minMediaMD ? undefined : `1px solid ${palette.invariantWhite}`
              }
              borderBottom={
                minMediaMD ? undefined : `1px solid ${palette.invariantWhite}`
              }
              py={minMediaMD ? undefined : 3}
            >
              {[
                {
                  city: "London",
                  address: [
                    "22 Gilbert Street",
                    "W1K 5HD London",
                    "United Kingdom",
                  ],
                },
                {
                  city: "Milan",
                  address: ["Piazza San Babila, 5", "20122 Milan", "Italy"],
                },
                {
                  city: "Luxembourg",
                  address: [
                    "14A, Rue des Bains",
                    "L-1212 Luxembourg",
                    "Grand Duchy of Luxembourg",
                  ],
                },
              ].map(({ city, address }) => (
                <Box key={city} width={minMediaMD ? undefined : "100%"}>
                  <Typography
                    variant="h1"
                    fontSize="2rem"
                    color={palette.invariantWhite}
                  >
                    {city}
                  </Typography>

                  <Stack mt={1} flexDirection="column">
                    {address.map((line) => (
                      <Typography
                        key={line}
                        fontWeight={400}
                        fontSize="1rem"
                        color={palette.accentBg200}
                      >
                        {line}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Box>

          <Box>
            <OutlineIconLogo
              height={minMediaMD ? "9.375rem" : "5rem"}
              fill={palette.invariantWhite}
            />
          </Box>

          <Stack flexDirection="row" gap={5}>
            <Box>
              <Link href={`mailto:${infoEmail}`} underline="none">
                <Typography
                  fontWeight={600}
                  fontSize="0.875rem"
                  color={palette.invariantWhite}
                >
                  {infoEmail}
                </Typography>
              </Link>
            </Box>

            <Box>
              <Link
                href={linkedinUrl}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Typography
                  fontWeight={600}
                  fontSize="0.875rem"
                  color={palette.invariantWhite}
                >
                  LinkedIn
                </Typography>
              </Link>
            </Box>
          </Stack>
        </Section>
      </Box>
    </>
  );
};
